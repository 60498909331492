<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/usermanagement/usermanagementlist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
     <h4 style="text-align: left;" class="bgl">基本信息</h4>
    <div class="form2 fel">
        <div>
            <el-image  style="width: 60px; height: 60px;    padding: 0;" :src="form.headimg"></el-image>
        </div>
        <div class="mglf">
            <div>账号：<input type="text" disabled v-model="form.phone"></div>
            <div v-if="form.sex">性别：<input v-if="form.sex==2" type="text" disabled value="女"><input v-if="form.sex==1" type="text" disabled value="男"></div>
            <div v-if="!form.sex">性别：<input  type="text" disabled value=""></div>
        </div>
        <div class="mglf">
            <div>认证用户：<input v-if="form.is_authentication==1" type="text" disabled value="是"><input v-else type="text" disabled value="否"></div>
            <div>用户编号：<input type="text" disabled v-model="form.uid"> </div>
        </div>
        <div class="mglf">
            <div>昵称：<input type="text" style="width: 220px;" disabled v-model="form.nickname"></div>
            <div>加入时间： <input type="text"  disabled v-model="form.community_created_at"></div>
        </div>
        <div class="mglf">
            <div>地区：<input type="text" style="width: 220px;" disabled v-model="form.showcity"></div>
            <div v-if="form.authentication.length != 0">
              认证用户类型：
              <input v-if="form.authentication.length != 0 && form.authentication[0].category==1" type="text" disabled value="普通用户">
              <input v-if="form.authentication.length != 0 && form.authentication[0].category==2" type="text" disabled value="kol">
              <input v-if="form.authentication.length != 0 && form.authentication[0].category==3" type="text" disabled value="商家">
            </div>
        </div>
    </div>
    
    <h4 style="text-align: left;" class="bgl">社区信息</h4>
    <div class="form2 fel">
        <div >
            <div>达人值：<input type="text" disabled v-model="form.talent_integral"></div>
            <div>精选内容数：<input type="text" disabled v-model="form.recommend"></div>
            <div>被举报次数：<input type="text" disabled v-model="form.report_count"></div>
        </div>
        <div class="mglf">
            <div>内容总数：<input type="text" disabled v-model="form.article_count">  </div>
            <div>被收藏总数：<input type="text" disabled v-model="form.collection_num">  </div>
            <div>关注话题数：<input type="text" disabled v-model="form.topic_count">  </div>
        </div>
        <div class="mglf">
            <div>粉丝数：<input type="text" disabled v-model="form.fans_user_count">  </div>
            <div>被屏蔽数：<input type="text" disabled v-model="form.shiled_count">  </div>
            <div>关注用户数：<input type="text" disabled v-model="form.follow_user_count">  </div>
        </div>
        <div class="mglf">
            <div>获赞总数：<input type="text" disabled v-model="form.liked">  </div>
            <div>违规次数：<input type="text" disabled v-model="form.violation_count">  </div>
        </div>
    </div>
         <h4  class="form2 bgl">兴趣选择</h4>
         <div class="fel">
             <div class="mgrt" v-for="(int,index) in form.interest" :key="int.id">
               兴趣{{index+1}}：<input type="text" disabled v-model="int.name">
               </div>
         </div>
    <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat2'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          account: '',
          password: '',
          password_confirmation: '',
          phone: '',
          email: '',
          name: '',
        },
        options:[],
    }
  },filters: {
      filtedate: function(date) {
        return formatDate(date*1000);
      }
    },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      if(postdata.account == '' ||postdata.password == '' ||postdata.password_confirmation == ''||postdata.role == ''){
        _this.$message.error('请填写必填项');
        return
     }
      axios.post(config.user,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '编辑成功',
                type: 'success'
              });
              _this.goNav('/user/userlist');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        axios.get(config.consumerInfo,{params:{id:obj.keyid}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
              if(_this.form.province && _this.form.city){
                _this.form.showcity = (_this.form.province.name == _this.form.city.name?_this.form.province.name:_this.form.province.name)+(_this.form.city.name+_this.form.area?'-'+_this.form.area.name:'');
              }else{
                _this.form.showcity = (_this.form.province?_this.form.province.name:'') + (_this.form.city?'-'+_this.form.city.name:'') + (_this.form.area?'-'+_this.form.area.name:'');
              }
              
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>
.form2{
    text-align: left;
}
.mglf{
    margin-left: 100px;
}
.mgrt{
    margin-right: 20px;
}
.fel{
    display: flex;
}
.fel div{
  margin: 5px 0px;
}
.el-icon-close:before{
  font-size: 24px;
}

div{
    vertical-align: middle;
    font-size: 16px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 0 0;
}.fel div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 18px 10px 0;
    box-sizing: border-box;
    font-weight: bold;
}
.fel div input{
  height: 20px;
}.bgl{
    background-color: aliceblue;
    padding: 10px;
    margin-bottom: 5px;
}
</style>
